export default {
  aboutUs: {
    nav1: 'ABOUT US',
    nav2: 'About Us',
    mode1: {
      title1: 'Guangdong Aofei NewEnergy',
      cont: 'Guangdong Aofei New Energy Co., Ltd. was established in Guangzhou, China in 2021. As a wholly-owned subsidiary of Aofei Data, a company listed on the A-share market (stock code: 300738), Aofei New Energy focuses on the distributed photovoltaic market in China. The company is dedicated to the investment development, construction management, technological research and development, and operational services of distributed photovoltaic projects. To date, we have constructed power stations in more than ten provinces across China, rapidly emerging as a leader in the industrial and commercial photovoltaic sector.<br /><br />With a global perspective, Aofei New Energy launched its international operations from Singapore in 2023, making a full-scale entry into overseas markets to serve both individual and industrial clients. Leveraging a strong R&D team and cutting-edge technology concepts, we collaborate with top industry players to provide customers with comprehensive, one-stop green energy solutions throughout the entire lifecycle, from demand analysis and design planning to construction implementation and subsequent operations.'
    },
    mode2: {
      title2: 'Performance',
      cont1: 'Cover Countries/Regions',
      cont2: 'Solar Power Plants',
      cont3: 'Capacities'
    },
    mode3: {
      title3: 'Business Mode',
      cont1: 'Business-to-Business',
      cont2: 'Energy Management Contract',
      cont3: 'Power Purchase Agreement'
    },
    mode4: {
      title4: 'Qualifications & Certifications',
    },
    mode5: {
      title5: 'Company Vision',
      cont1: 'In the era of dual carbon goals, Aofei New Energy adheres to the principles of high standards, high quality, and high returns in its cooperative approach.',
      cont2: 'We embrace challenges and seize opportunities with the slogan “Aofei, powering your life.” ',
      cont3: 'We continue to explore opportunities to develop in the new energy sector by actively promoting the application and advancement of green energy. '
    }
  },
  solutions: {
    residential: {
      menuPic: {
        title: 'Residential Solar Installation',
        cont: 'Save The Earth While Unlocking Greater Cost Savings. Install solar panels, inverter and batteries at your home. Save costs and sell back surplus electricity back to the grid.',
        button: 'Get a Free Quote'
      },
      nav1: 'ABOUT US',
      nav2: 'SOLUTIONS',
      nav3: 'PV Installation',
      nav4: 'Residential Solar Installation',
      box1: {
        title: 'Why should we use solar power?',
        cont: 'Solar energy is an inexhaustible source of power that generates electricity without producing carbon dioxide or other pollutants, making it a truly clean energy. Solar power generation technology is increasingly becoming the optimal solution for people to address energy crises and achieve sustainable development.',
      },
      box2: {
        title: 'Benefits',
        cont1: 'Save your electricity bills',
        cont2: 'Reduce carbon footprint',
        cont3: 'Sell your surplus back to the grid',
        cont4: 'Prolong lifespan of your roof',
        cont5: 'Passive cooling of your house',
        cont6: 'Increase the valuation of your house'
      },
      box3: {
        title: 'Project Development Process',
        cont1: {
          title: 'Step 1',
          cont: 'Consultation & Assessment'
        },
        cont2: {
          title: 'Step 2',
          cont: 'Site Visit & Due Diligence'
        },
        cont3: {
          title: 'Step 3',
          cont: 'Proposal Review & Acceptance'
        },
        cont4: {
          title: 'Step 4',
          cont: 'Solar System Installation'
        },
        cont5: {
          title: 'Step 5',
          cont: 'Grid-connection Acceptance & Earn Savings'
        }
      },
      box4: {
        title: 'Advantages of the Scheme',
        box1: {
          title1: 'Zero Burden & Zero Concern',
          cont4: 'No upfront payment. All license and installation fees are borne by us.',
          cont5: 'Maintenance-free. We provide maintenance during the agreement period.',
          cont6: 'It is estimated that we can save you 40% of your electricity bill each month.',
          cont7: 'Please note that the actual situation may vary in different regions.',
          button: 'Contact us'
        },
        // box1: {
        //   title1: 'MSPPA',
        //   cont1: 'Affordable & Hassle-free',
        //   cont2: 'From',
        //   cont3: 'month',
        //   cont4: '$0 upfront, all licenses and costs of installation bear by provider',
        //   cont5: 'Free maintenance in tandem with the agreement’s tenure',
        //   cont6: 'Save more than 40% each month on your electrical bills',
        //   button: 'Get a Free Quote'
        // },
        // box2: {
        //   title1: 'Upfront Purchase',
        //   cont1: 'Pay upfront, own your system, enjoy savings and earn passive income from excess solar energy generated!',
        //   cont2: 'Pricing depends on site and requirements',
        //   cont3: 'Own the system immediately',
        //   cont4: 'Inclusive of 1 year warranty & maintenance',
        //   cont5: 'Subsequent maintenance to be arranged separately at owners’ costs',
        //   button: 'Get a Free Quote'
        // }
      }
    },
    commercial: {
      menuPic: {
        title: 'Commercial Solar Installation',
        cont: 'Save The Earth While Unlocking Greater Cost Savings. Install solar panels, inverter and batteries at your home. Save costs and sell back surplus electricity back to the grid.',
        button: 'Get a Free Quote'
      },
      nav1: 'ABOUT US',
      nav2: 'SOLUTIONS',
      nav3: 'PV Installation',
      nav4: 'Commercial Solar Installation',
      box1: {
        cont1: 'No Upfront Costs',
        cont2: 'Immediate Cost Savings via Low Solar Tariff',
        cont3: 'No Price Fluctuations',
        cont4: 'Integrated Operation and Maintenance Services'
      },
      box2: {
        title: 'Project Development Process',
        cont1: {
          title: 'Step 1',
          cont: 'Consultation & Assessment'
        },
        cont2: {
          title: 'Step 2',
          cont: 'Site Visit & Due Diligence'
        },
        cont3: {
          title: 'Step 3',
          cont: 'Proposal Review & Acceptance'
        },
        cont4: {
          title: 'Step 4',
          cont: 'Solar System Installation'
        },
        cont5: {
          title: 'Step 5',
          cont: 'Grid-connection Acceptance & Earn Savings'
        }
      },
      box3: {
        title: 'Pricing',
        box1: {
          title1: 'MSPPA',
          cont1: 'Affordable & Hassle-free',
          cont2: 'Higher monthly discounts',
          cont4: '$0 upfront, all licenses and costs of installation bear by provider',
          cont5: '15-20 years agreement',
          cont6: 'Up to x% discount on electricity',
          cont7: 'Free maintenance in tandem with the agreement’s tenure',
          cont8: 'Minimum energy gurantee',
          button: 'Get a Free Quote'
        },
        box2: {
          title1: 'Upfront Purchase',
          cont1: 'Pay upfront, own your system, enjoy savings and earn passive income from excess solar energy generated!',
          cont2: 'Pricing depends on site and requirements',
          cont3: 'Own the system immediately',
          cont4: 'Inclusive of 1 year warranty & maintenance',
          cont5: 'Subsequent maintenance to be arranged separately at owners’ costs',
          button: 'Get a Free Quote'
        }
      }
    },
    photovoltaic: {
      nav1: 'ABOUT US',
      nav2: 'SOLUTIONS',
      nav3: 'Household PV Energy Storage Solution',
      box1: {
        title: 'Reliable All-in-one Energy Storage Solution',
        cont1: 'Enjoy clean energy while reducing your electrical bills.',
        cont2: 'Generate clean energy from your own solar system at home.',
        cont3: 'An ideal solution by AOFEI that is safe and easy to install for residential users.',
        cont4: 'During the day, basked in sunlight, AOFEI energy storage system converts solar energy into "green" electricity for home.',
        cont5: "When the blackout happens, AOFEI energy storage system seamlessly transitions into becoming the home's primary energy source, ensuring uninterrupted operation of critical appliances.",
        cont6: 'As night falls, our energy storage system seamlessly springs into action, harnessing the electricity stored in the battery during the day to consistently provide a continuous supply of green energy.'
      },
      box2: {
        cont1: {
          title: 'Reliable and efficient system',
          cont: 'Stable performance against harsh environments<br />1. HALT test.<br />2. Extreme high and low temperature test.<br />3. Salt spray test.<br />4. Full load aging test.'
        },
        cont2: {
          title: 'Fast charging',
          cont: 'With the improvement of charging efficiency,<br />more electricity can be stored in the same<br />period of time'
        },
        cont3: {
          title: '2 times DC overmatching<br />1.5 times DC input power',
          cont: 'Supports up to 200% DC overmatching,<br /> allowing efficient conversion of solar energy,<br /> and storage of excess energy in batteries<br /> for night or backup usage.'
        },
        cont4: {
          title: 'Seamless and Uninterrupted Solution',
          cont: 'All series inverters can be connected to diesel generators.In case of power outage and poor sunlight conditions,diesel can still be used to ensure electricity. '
        },
      },
      box3: {
        title: 'Case',
        case1: 'PHILIPPINES',
        case2: 'VIETNAM',
        button: 'More'
      }
    },
    iac: {
      nav1: 'ABOUT US',
      nav2: 'SOLUTIONS',
      nav3: 'Commercial & Industrial Energy Storage System',
      title1: 'The average payback period for a 1 MW aofei plant is around 3 years with local subsidies, and about 5 years without subsidies. However, the actual payback time depends on various factors, such as local regulations, power consumption, and on-grid tariff policies.',
      title2: 'AOFEI C&I PV inverters + DataHub 1000 = Zero Feed-in Control',
      box1: {
        cont1: 'These plants can be built on building rooftops or open spaces to form self-contained power plants and generate profits by selling excess electricity to the grid.',
        cont2: 'All the inverter data can be monitored on Aofei Cloud platform.',
        cont3: 'With all C&I inverters connected to the 3 available RS485 ports on DataHub1000, the output power and export power of the whole power plant can be set and controlled in accordance to the site requirements.'
      },
      box2: {
        cont1: 'Support up to',
        cont2: 'units of on-grid inverters',
        cont3: 'Compatible String Inverters',
        cont4: 'Device',
        unit: '60'
      },
      box3: {
        cont1: 'Each port can communicate',
        cont2: 'with up to 20 inverters in',
        cont3: 'cascading daisy chain.',
        cont4: 'Up to 60 inverters per DataHub.',
        cont5: 'Interact with smart meters such as the',
        cont6: 'DTSU666-CT from Aofei.'
      },
      box4: {
        title: 'Case',
        case1: '',
        case2: 'Guangzhou',
        case3: 'Meizhou',
        button: 'More'
      }
    },
    microGrid: {
      nav1: 'ABOUT US',
      nav2: 'SOLUTIONS',
      nav3: 'Solar Micro-grid System',
      title: 'Diesel Generator Companion',
      cont1: 'About "DGC"',
      cont2: 'AOFEI seek to achieve “diesel generator’s 2.0 era” through “diesel generator companion” which improves efficiency by optimizing energy consumption based on variable load usage. This environmental preservation effort helps businesses realize direct cost savings while reducing diesel usage, noise pollution and carbon emission. ',
      box1: {
        title: 'Adaptable In Applications',
        cont1: 'NO ELECTRICITY SUPPLY',
        cont2: 'UNSTABLE ELECTRIC SUPPLY',
        cont3: 'HIGH DIESEL PRICE',
        cont4: 'NOISE-SENSITIVE ENVIRONMENTS'
      },
      box2: {
        title: 'Operation Mode',
        cont1: {
          title: 'Small scale',
          cont: 'Farm/Construction Site',
        },
        cont2: {
          title: 'Medium scale',
          cont: 'Island',
        },
        cont3: {
          title: 'Large scale',
          cont: 'Mines/Factories In Underdeveloped Areas',
        }
      }
    },
    case: {
      nav1: 'ABOUT US',
      nav2: 'CASES',
      tip1: 'Installed capacity',
      tip2: 'Investment amount',
      tip3: 'Annual generation capacity',
      tip4: 'Save standard coal',
      tip5: 'Annual reduction of carbon dioxide emissions',
      tip6: 'Size',
      guangzhou_money:"36,000,000 CNY",
      meizhou_money:"8,600,000 CNY",
      jiangmen_money:"20,000,000 CNY",
      jieyang_money:"8,470,000 CNY",
      dongguan_money:"5,610,000 CNY",
      langfang_money:"15,000,000 CNY",
      baoji_money:"13,110,000 CNY",
      singepo1_money:"145,000（SGD）",
      singepo2_money:"182,000（SGD）",
      singepo3_money:"137,800（SGD）",
      guangzhou_annual_electricity_generation:"10,000 MWh",
      meizhou_annual_electricity_generation:"2,280 MWh",
      jiangmen_annual_electricity_generation:"5,030 MWh",
      jieyang_annual_electricity_generation:"2,270 MWh",
      dongguan_annual_electricity_generation:"1,310 MWh",
      langfang_annual_electricity_generation:"5,600 MWh",
      baoji_annual_electricity_generation:"4,490 MWh",
      country1: 'CHINA',
      country2: 'SINGAPORE',
      country3: 'PHILIPPINES',
      country4: 'VIETNAM',
      country5: 'INDONESIA',
      country6: 'PAKISTAN',
      country7: 'THAILAND BANGKOK',
      country8: 'INDIA',
      box1: {
        title: 'Commercial Solar Installation.',
        cont1: {
          address: 'Guangzhou, GuangDong',
          company: 'CSSC Huangpu Wenchong Shipyard'
        },
        cont2: {
          address: 'Meizhou, GuangDong',
          company: 'Meizhou Weiyou Wear-resistant Material Co.,Ltd.'
        },
        cont3: {
          address: 'Jiangmen, GuangDong',
          company: 'Guangdong Rosery Bath Equipment Co.,Ltd.'
        },
        cont4: {
          address: 'Jieyang, GuangDong',
          company: 'Puning Rongmao Printing and Dyeing Co.,Ltd.'
        },
        cont5: {
          address: 'Dongguan, GuangDong',
          company: 'GuangDong Voion Eco Packaging Industrial Co.,Ltd.'
        },
        cont6: {
          address: 'Langfang, HeBei',
          company: 'Langfang Xunyun Data Center'
        },
        cont10: {
          address: 'Shaanxi, Baoji',
          company: 'Longhengda Aluminum Industry Co., Ltd. of Baoji City'
        },
        cont7: {
          address: 'Singapore',
          company: '5 Kallang Way 2A Lubritrade Centre Singapore 347494.'
        },
        cont8: {
          address: 'Singapore',
          company: '36 Tuas Ave 8 Singapore 639250.'
        },
        cont9: {
          address: 'Singapore',
          company: '3 Chin Bee Ave, Singapore 619928.'
        }
      },
      box2: {
        title: 'Residential Solar Installation.',
        cont9: {
          address: 'Singapore',
          company: "53 St Patrick's Rd Singapore 424168."
        }
      },
      box3: {
        title: 'Energy storage system.',
        cont10: {
          address: 'X1-SMART',
          company: "Temple drive,Greenmeadows subdivision,Quezon city"
        },
        cont11: {
          address: 'X3-MEGA G2',
          company: "Long An,Long Thanh,Dong Nai Province,Vietnam"
        },
        cont12: {
          address: 'X3-PRO G2',
          company: "Awis Shalim Bali,Indonesia"
        },
        cont13: {
          address: 'X3-FORTH',
          company: "Kohat,Khyber Pakhtunkhwa,Pakistan"
        },
        cont14: {
          address: 'X3-MIC',
          company: "Bangkok"
        },
        cont15: {
          address: 'X3-PRO G2',
          company: "Harsh Industry,Khatraj Gidc,Dist-Gandhinagar,Gujarat"
        },
      }
    },
    contactUs: {
      nav1: 'ABOUT US',
      nav2: 'CONTACT US',
      box1: {
        cont1: {
          title: 'Address',
          intro: 'Floor 45, international Metropolitan Plaza, No.68 Huacheng Road, Tianhe District, Guangzhou, China',
          intro1: '5 Kallang Way 2A #06-08 Lubritrade Centre Singapore 347494'
        },
        cont2: {
          title: 'E-mail',
          intro: 'export@aofeine.com',
          intro1: 'contact@aofeine.com.sg'
        },
        cont3: {
          title: 'Phone',
          intro: '+86 4008869117',
          intro1: '+65 96875725'
        },
        cont4: {
          title: 'Web',
          intro: 'www.aofeine.com',
          intro1: 'www.aofeine.com'
        }
      },
      box2: {
        title: 'Send Us A Message',
        tip: 'Get in touch with our team and advisors through the form below.',
        label1: 'Your name',
        label2: 'Email address',
        label3: 'Phone number',
        label4: 'Business address',
        label5: 'Select Country',
        label6: 'Message',
        button1: 'SEND',
        button2: 'Message',
      }
    },
    footer: {
      title1: 'OPERATION HOURS',
      title2: 'CONTACT US',
      cont1: 'Guangdong Aofei New Energy',
      cont2: 'Floor 45, international Metropolitan Plaza, No.68 Huacheng Road, Tianhe District, Guangzhou, China',
      cont3: 'Aofei New Energy SG Pte. Ltd.',
      cont4: '5 Kallang Way 2A #06-08 Lubritrade Centre Singapore 347494'
    },
    menu: {
      title1: 'ABOUT US',
      title2: 'SOLUTIONS',
      title3: 'PV Installation',
      title4: 'Residential Solar Installation',
      title5: 'Commercial Solar Installation',
      title6: 'Household PV Energy Storage Solution',
      title7: 'Commercial & Industrial Energy Storage System',
      title8: 'Solar Micro-grid System',
      title9: 'CASES',
      title10: 'CONTACT US',
    }
  }
}